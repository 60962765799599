<template>
  <view class="class_notes_modal_container">
    <el-dialog
      title="上课须知"
      v-model="visible"
      :center="true"
      ref="note_modal"
      width="35%"
      :before-close="handleClose"
    >
      <p class="note_text">
        约课成功后，请及时跟老师联系，上课前3小时内取消课程预约，会扣减相应课时。
      </p>
      <note-card :card-info="teacherObj"></note-card>
      <note-card :card-info="softWareObj"></note-card>
      <template #footer class="dialog-footer">
        <el-button type="primary" @click="handleClose">关闭</el-button>
      </template>
    </el-dialog>
  </view>
</template>
<script>
import NoteCard from './NoteCard.vue';

export default {
  name: 'classNotes',
  components: { NoteCard },
  props: {
    info: {
      type: Array,
      default: () => [],
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      teacherObj: {
        title: '联系班主任',
        subTitle: '约课问题请微信咨询班主任：',
        value: '',
        key: 'WeChatNumber',
      },
      softWareObj: {
        title: '下载上课软件Zoom',
        subTitle: '下载地址：',
        value: '',
        key: 'DownloadUrl',
      },
    };
  },
  watch: {
    info: {
      handler(val) {
        if (val) {
          this.teacherObj.value = (
            val.find((item) => item.value === 'WeChatNumber') || {}
          ).key;
          this.softWareObj.value = (
            val.find((item) => item.value === 'DownloadUrl') || {}
          ).key;
        }
      },
      deep: true,
      immediate: true,
    },
    dialogVisible(val) {
      this.visible = val;
    },
  },
  methods: {
    handleClose() {
      this.$emit('update:dialogVisible', false);
    },
  },
};
</script>
<style lang="scss" scoped>
  .class_notes_modal_container {
    .note_text {
      width: 100%;
      font-size: 16px;
      font-weight: 400;
      color: #4e596f;
      line-height: 30px;
      margin-bottom: 15px;
      margin-top: 15px;
    }
  }
</style>
