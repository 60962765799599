<template>
  <div class="wrapper">
    <div v-for="(e, i) in teacherList" :key="i" class="group">
      <div class="avatar">
        <img :src="e.children[0].teacherPic" />
      </div>
      <div class="teacherInfo">
        <div class="teacherName">{{ e.children[0].teacherName }}</div>
        <!-- <div class="hours">可约课时：{{ 3 }}</div> -->
      </div>
      <div class="timeInfo">
        <div v-for="(c, i) in e.children" :key="i" class="timeItem">
          <div class="leftTime">
            <div class="localTime">{{ getLocalBeginTime(c) }}-{{ getLocalEndTime(c) }}</div>
            <div class="beijingTime" v-if="isCN()">
              （北京时间{{ showBjTime(c.beginTime, "YYYY-MM-DD HH:mm") }}-{{ showBjTime(c.endTime, "HH:mm") }}）
            </div>
          </div>
          <div class="operation" @click="bookNow && bookNow(c)" v-if="c.status === 0">
            预约
          </div>
          <div class="operationStatus" v-else-if="e.status === 1">
            已预约
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  userCurrentTimestemp,
  userCurrentEndTimestemp,
  transferCNTimeToLocal,
  localShowTime,
  showBjTime,
  isCN,
  localShowTimeNew,
} from "@/utils/timezone";


export default {
  props: {
    data: Array,
    bookNow: Function,
  },
  computed: {
    teacherList() {
      let result = (this.data || []).reduce((last, e) => {
        const lastIndex = last.findIndex(el => el.teacherId === e.teacherId)

        if (lastIndex >= 0) {
          last[lastIndex].children = [...last[lastIndex].children, e]
        } else {
          last.push({
            teacherId: e.teacherId,
            children: [e]
          })
        }
        return last
      }, [])


      console.log({ result })

      return result;
    }
  },
  data() {

  },
  methods: {
    showBjTime,
    isCN,
    getLocalBeginTime(row) {
      const { start, end } = localShowTimeNew(row.beginTime, row.endTime)
      const { hour, minute } = start;
      return `${hour}:${minute}`
    },
    getLocalEndTime(row) {
      const { start, end } = localShowTimeNew(row.beginTime, row.endTime)
      const { hour, minute } = end;
      return `${hour}:${minute}`
    }

  },
};
</script>

<style lang="scss" scoped>
.wrapper {}


.group {
  margin-bottom: 24px;

  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-areas: "avatar teacherInfo"
    ". timeInfo";
  column-gap: 10px;
  row-gap: 8px;
}

.avatar {
  grid-area: avatar;

  img {
    width: 40px;
    height: 40px;
    border-radius: 18px;
  }
}

.teacherInfo {
  grid-area: teacherInfo;
  align-self: center;

  .teacherName {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #333643;
  }

  .hours {
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #B5B6BA;
  }

}

.timeInfo {
  grid-area: timeInfo;

  background: #F5F6FA;
  border-radius: 16px;
  padding: 16px 20px;

  .timeItem {
    display: flex;
    justify-content: space-between;
  }
}


.leftTime {
  display: flex;
  align-items: center;
}

.localTime {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #333643
}

.beijingTime {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #8E95A3;
}

.timeItem {
  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.operation {
  height: 24px;
  border-radius: 13px;
  border: 1px solid #3377FF;
  line-height: 22px;
  padding: 0 14px;


  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #3377FF;

  cursor: pointer;
}
</style>
