<template>
  <div>
    <back-btn :handle-click="() => $router.back()"></back-btn>
    <book-tip :count="oralCount">
      <div class="class_notes_tip" @click="dialogVisible = true"><img src="@/assets/img/oralStage/xuzhi@2x.png"
          class="tipsIcon" />上课须知</div>
    </book-tip>
    <div class="contentWrapper">
      <my-tabs class="week_tab_bar" :tabs="tabs" :custom-style="customStyle" v-model:activeTab="activeTab"></my-tabs>
      <MyTeacherList :data="bookCourseData || []" :bookNow="bookNow" />
      <!-- <my-table class="table" :columns="columns" :table-data="bookCourseData || []" :is-page="false"
        :handle-arr="handleActions" /> -->

    </div>

    <div class="dialogResetBook">
      <el-dialog title="提示" v-model="bookVisible" :center="true" width="25%">
        <p class="tip_text">
        <div>
          您确定要预约
        </div>
        <p class="strong"> {{ activeRow.teacherName }}老师{{ localShowTime(activeRow.beginTime, activeRow.endTime) }}</p>
        <p class="strong" v-if="!isCN()" style="font-size:13px;color:#a0a2ab;">(北京时间 {{
            showBjTime(activeRow.beginTime, "YYYY-MM-DD HH:mm")
        }}-{{ showBjTime(activeRow.endTime, "HH:mm") }})</p>
        <p>的{{ activeRow.courseName }}课程吗？预约成功后将<span class="blueStrong">扣减1课时</span></p>
        </p>
        <template #footer>
          <span class="dialog-footer">
            <el-button class="footer_btn" type="primary" plain @click="bookVisible = false">取消</el-button>
            <el-button class="footer_btn" type="primary" @click="handleBook">确认</el-button>
          </span>
        </template>
      </el-dialog>
    </div>

    <class-notes v-model:dialogVisible="dialogVisible" :info="classNotes"></class-notes>

  </div>
</template>
<script>
import BackBtn from "@/components/BackBtn.vue";
import { getTargetDate, getTargetDay } from "@/utils/time";
import {
  userCurrentTimestemp,
  userCurrentEndTimestemp,
  transferCNTimeToLocal,
  localShowTime,
  showBjTime,
  localBeginTime,
  isCN,
  localShowTimeNew,
} from "@/utils/timezone";
import MyTabs from "../../components/MyTabsNew.vue";
import MyTeacherList from "../../components/MyTeacherList.vue";
import BookTip from "./components/BookTip.vue";
import MyTable from "../../components/MyTable.vue";
import { courseStatus } from "./config";
import { connectState } from "../../utils/wyUtil";
import ClassNotes from "./components/ClassNotes.vue";

export default {
  components: {
    ClassNotes,
    MyTable,
    BookTip,
    MyTabs,
    BackBtn,
    MyTeacherList,
  },
  data() {
    return {
      activeTab: 0,
      activeRow: {},
      customStyle: { padding: "0 10px", fontSize: "15px" },
      tabs: [
        {
          up: `今天`,
          // up: `${getTargetDay(0)}`,
          down: `${getTargetDate(0)}`,
          label: `${getTargetDay(0)}（${getTargetDate(0)})`,
          value: 0,
        },
        {
          up: `${getTargetDay(1)}`,
          down: `${getTargetDate(1)}`,
          label: `${getTargetDay(1)}（${getTargetDate(1)})`,
          value: 1,
        },
        {
          up: `${getTargetDay(2)}`,
          down: `${getTargetDate(2)}`,
          label: `${getTargetDay(2)}（${getTargetDate(2)})`,
          value: 2,
        },
        {
          up: `${getTargetDay(3)}`,
          down: `${getTargetDate(3)}`,
          label: `${getTargetDay(3)}（${getTargetDate(3)})`,
          value: 3,
        },
        {
          up: `${getTargetDay(4)}`,
          down: `${getTargetDate(4)}`,
          label: `${getTargetDay(4)}（${getTargetDate(4)})`,
          value: 4,
        },
        {
          up: `${getTargetDay(5)}`,
          down: `${getTargetDate(5)}`,
          label: `${getTargetDay(5)}（${getTargetDate(5)})`,
          value: 5,
        },
        {
          up: `${getTargetDay(6)}`,
          down: `${getTargetDate(6)}`,
          label: `${getTargetDay(6)}（${getTargetDate(6)})`,
          value: 6,
        },
      ],
      columns: [
        { prop: "teacherName", label: "教师名称" },
        {
          prop: "beginTime",
          label: "上课时间",
          width: 250,
          renderDom: (row) => {
            return `<div>
                <p style="font-size:15px;"">${localShowTime(
                  row.beginTime,
                  row.endTime
                )}</p>
                <p style="font-size:12px;color:#a0a2ab;display:${
                  this.isCN() ? "none" : "block"
                }">(北京时间 ${showBjTime(
              row.beginTime,
              "YYYY-MM-DD HH:mm"
            )}-${showBjTime(row.endTime, "HH:mm")})</p>
              </div>`;
          },
        },
        {
          prop: "status",
          label: "预约状态",
          render: (row) => courseStatus[row.status],
        },
      ],
      handleActions: [
        // 预约状态 0：未预约，1：已预约，2：删除 ,
        {
          text: "立即预约",
          visible: (row) => row.status === 0,
          action: this.bookNow,
        },
        {
          text: "取消预约",
          visible: (row) => row.status === 1,
          disabled: true,
        },
      ],
      bookVisible: false,
      dialogVisible: false,
    };
  },
  watch: {
    activeTab(val) {
      this.getBookCourseList(val);
    },
  },
  computed: {
    ...connectState("oral", ["bookCourseData", "oralCount", "classNotes"]),
  },
  created() {
    this.getBookCourseList(this.activeTab);
    this.getOralCount();
    this.queryClassNotes();
  },
  methods: {
    isCN,
    transferCNTimeToLocal,
    localShowTime,
    showBjTime,
    bookNow(row) {
      if (row.status === 0) {
        this.activeRow = row;
        this.bookVisible = true;
      }
    },
    // cancelBookNow(row){},
    queryClassNotes() {
      this.$store.dispatch("oral/getClassNotes");
    },
    getBookCourseList(next) {
      this.$store.dispatch("oral/queryBookCourseList", {
        startTime: userCurrentTimestemp(next),
        endTime: userCurrentEndTimestemp(next),
      });
    },
    getOralCount() {
      this.$store.dispatch("oral/fetchOralCount");
    },
    handleBook() {
      let params = {
        courseId: this.activeRow.courseId,
        callback: () => {
          this.bookVisible = false;
          this.activeRow = {};
          this.getOralCount();
          this.getBookCourseList(this.activeTab);
          this.$message({ type: "success", message: "约课成功" });
        },
      };
      if (!this.isCN()) {
        params.time = localBeginTime(this.activeRow.beginTime)
      } else {
        if (params.time) delete params.time
      }
      this.$store.dispatch("oral/bookedCourse", params);
    },
  },
};
</script>
<style  lang="scss" >
.dialogResetBook {
  & .el-dialog {
    border-radius: 16px;
  }

  & .el-dialog__close {
    display: none;
  }
}
</style>
<style lang="scss" scoped>
.strong {
  font-weight: bold;
}

.blueStrong {
  color: rgba(51, 119, 255, 1);
}

.class_notes_tip {
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #3377ff;
  text-decoration: underline;
}

.tipsIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.week_tab_bar {
  margin-bottom: 20px;
  font-size: 15px;
}

.table {
  border-radius: 6px;
  overflow: hidden;
}

.tip_text {
  width: 100%;
  text-align-last: center;
  line-height: 24px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #333643;
}

.contentWrapper {
  background: #ffffff;
  border-radius: 16px;
  padding: 24px 16px;
}

.footer_btn {
  border-radius: 14px;
  border: none;
}
</style>
