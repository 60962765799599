<template>
  <div class="myTabs">
    <ul>
      <li :class="{ active: activeTab === item.value, hover: type === 'practice', theItem: true }"
        v-for="(item, i) in tabs" :key="i" @click="type === 'practice' ? '' : clickTab(item.value)">
        <div class="up">
          {{ item.up }}
        </div>
        <div class="down">
          {{ item.down }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "myTabs",
  props: {
    tabs: Array,
    activeTab: {
      type: [String, Number],
      default: "",
    },
    customStyle: {
      type: Object,
      default: () => ({}),
    },
    type: String,
  },
  data() {
    return {};
  },
  methods: {
    clickTab(val) {
      this.$emit("update:activeTab", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.theItem {
  width: 96px;
  height: 68px;
  background: #F8F8F8;
  border-radius: 16px;
  color: rgba(51, 54, 67, 1);
  text-align: center;
  margin-right: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.active {
    background: rgba(51, 119, 255, 1);

    .up {
      color: white;
    }

    .down {
      color: white;
    }
  }

  .up {
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #333643;
    margin-bottom: 4px;

  }

  .down {
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #333643;
  }
}

.myTabs ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 44px;



  li {
    /* height: 40px;
    line-height: 40px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0px 2px 10px 0px rgba(120, 154, 172, 0.18);
    color: #a0a2ab;
    padding: 0 30px;
    margin-right: 15px;
    cursor: pointer;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px; */

    /* &.active {
      background: linear-gradient(52deg, #ff3366 0%, #ffaa1e 100%);
      color: #fff;
    }

    &.hover {
      cursor: not-allowed;
    } */
  }
}
</style>
