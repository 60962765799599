<template>
  <div class="book_course_tip_container">
    <div class="row_in_container">
      <p class=" text_container_timezone">
        <span>当前时区：</span>
        <span>{{ TZ_NAME_EN_CM }}</span>
      </p>
      <p class="text_container_lay">
        剩余 <span>{{ count }}</span> 课时
      </p>
    </div>
    <div class="slot_content">
      <slot></slot>
    </div>

  </div>
</template>
<script>
import { TZ_NAME_EN_CM } from '@/utils/timezone';

export default {
  name: 'bookTip',
  props: {
    count: Number,
  },
  data() {
    return {
      TZ_NAME_EN_CM,
    };
  },

};
</script>

<style lang="scss" scoped>
.text_container_lay {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #333643;
  line-height: 20px;
  line-height: 36px;
  margin-left: 16px;
  span{
    color: rgba(51, 119, 255, 1);
  }
}

.text_container_timezone {
  height: 36px;
  background: #FFFFFF;
  border-radius: 8px;
  line-height: 36px;
  padding: 0 16px;

  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #333643;
}

.book_course_tip_container {
  width: 950px;
  height: 63px;
  /* box-shadow: 0px 2px 16px 0px rgba(202, 205, 214, 0.47); */
  border-radius: 6px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .row_in_container {
    flex: 1;
    height: 28px;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .text_container {
      margin-right: 15px;
    }

    .text {
      color: #6D7278;
    }

    .content {
      color: #3377FF;
    }
  }

  .slot_content {
    width: 100px;
    flex: none;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: #3377FF;
    cursor: pointer;
  }
}
</style>
