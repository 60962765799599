<template>
  <div class="note_class_card_container">
    <p class="title">{{ cardInfo.title }}</p>
    <div class="list_item_wrapper borderBox">
      <p>{{ cardInfo.subTitle }}</p>
      <p class="view_text">
        <span :id="cardInfo.key" class="content_text">{{
          cardInfo.value
          }}</span>
        <span @click="handleCopyText" class="copy_btn">复制</span>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'noteCard',
  props: {
    cardInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    handleCopyText() {
      const val = document.getElementById(`${this.cardInfo.key}`).innerHTML;
      const input = document.createElement('input');
      input.setAttribute('readonly', 'readonly');
      input.setAttribute('type', 'text');
      input.setAttribute('value', val);
      document.body.appendChild(input);
      input.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        this.$message({ type: 'success', message: '复制成功' });
        console.log('复制成功');
      }
      document.body.removeChild(input);
    },
  },
};
</script>
<style lang="scss">
  .note_class_card_container {
    .title {
      font-size: 16px;
      font-weight: 500;
      color: #4e596f;
      line-height: 32px;
      margin-bottom: 5px;
    }
    .list_item_wrapper {
      background: #eff4fe;
      border-radius: 6px;
      padding: 10px;

      font-size: 15px;
      font-weight: 400;
      color: #4e596f;
      line-height: 30px;
      margin-bottom: 20px;
      .view_text {
        color: #3377ff;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        line-height: 30px;
        .content_text {
          width: 80%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .copy_btn{
          cursor: pointer;
        }
      }
    }
  }
</style>
